import NodeRSA from 'node-rsa';
const windowLocation = (typeof window !== 'undefined' && (window && window.location)) || {};
const {
    host='', protocol=''
} = windowLocation;

export default {
    queryLabelColor : '#6c757d',
    stageWrapperLabelColor : '#6c757d',
    functionLabelColor : '#6c757d',
    formLabelColor : '#6c757d',
    databaseAdminPermission : 'databaseAdmin',
    databaseDeveloperPermission : 'databaseDeveloper',
    adminPermission : 'admin',
    adminDeveloperPermission : 'adminDeveloper',
    paymentAdminPermission : 'paymentAdmin',
    paymentDeveloperPermission : 'paymentDeveloper',
    uiDeveloperPermission : 'uiDeveloper',
    apiDeveloperPermission : 'apiDeveloper',
    subscriptionAdminPermission : 'subscriptionAdmin',
    subscriptionDeveloperPermission : 'subscriptionDeveloper',
    viesSystemUsersPermission : 'viewSystemUsers',
    createSystemUserPermission : 'createSystemUser',
    updateSystemUserPermission : 'updateSystemUser',
    deleteSystemUserPermission : 'deleteSystemUser',
    devServerProxy : process.env.REACT_APP_ENV === 'dev' ? protocol + '//' + host.split(':')[0] : '',
    loginDomain : process.env.REACT_APP_LOGIN_DOMAIN,
    noVerifyUserRoutes : ['/', '/login', '/resetpassword', '/signup', '/subscriptionservice/signup/'],
    awsS3StorageLocation : 'AWS S3',
    azureStorageLocation : 'Azure Blob Storage',
    googleStorageLocation : 'Google Cloud Storage',
    vimeoStorageLocation : 'Vimeo',
    cardsWithFontAwesomeIcons : ['amex', 'discover', 'visa', 'diners_club', 'mastercard', 'jcb'],
    cardsWithIconsMap : {
        amex : 'fab fa-cc-amex',
        discover : 'fab fa-cc-discover',
        visa : 'fab fa-cc-visa',
        diners_club : 'fab fa-cc-diners-club',
        mastercard : 'fab fa-cc-mastercard',
        jcb : 'fab fa-cc-jcb',
        creditcard : 'far fa-credit-card'
    },
    subscriptionChargeType : 'subscription service',
    hideHeaderPaths : ['appbuilder', 'appviewer', 'signup', 'inhouzsignbuilder', 'pdfgeneratorbuilder'],
    appBuilderTheme : {
        dark : {
            backgroundColor : '#292929', //lighter
            lightBackgroundColor : '#4d4d4d', //lightest
            dullColor : 'rgba(255, 255, 255, 0.70)',
            sharpColor : '#FFFFFF',
            // sharpColor : 'rgb(217, 217, 217)',
            canvasWrapperBackground : '#878787',
            settingsHeaderBackground : '#202020', //darker
            darkerBackground : '#202020',
            borderColor : 'black',
            styleIconBackgroundDull : '#5e5e5e',
            styleIconBackgroundBright : '#6e6e6e',
            styleIconBorderColor : 'rgba(255, 255, 255, 0.20)'
        },
        light : {}
    },
    clientSecretKey : process.env.REACT_APP_CLIENT_SECRET_KEY,
    singlePageAppTypes : ['emailFunction', 'webComponent'],
    nonLifecycleAppTypes : ['emailFunction'],
    birdViewAppTypes : ['presentation'],
    documentAppTypes : ['pdfFunction', 'presentation', 'inhouzSign'],
    commitAppTypes : ['webComponent', 'webApp'],
    reservedParameterNames : [
        'inhouz_reserved_parameters_xyzh', 'event', 'inList', 'listItemData', 'listItemIndex',
        'user', 'userAgent', 'routeParameters', 'queryStringParameters', 'requestHeaders',
        'requestBody', 'App_Data', 'eventTargetValue', 'ENV'
    ],
    createSystemUserPermission : 'createSystemUser',
    editSystemUserPermission : 'updateSystemUser',
    deleteSystemUserPermission : 'deleteSystemUser',
    environmentList : ['development', 'test', 'production'],
    writeScheduledTaskPermission : 'writeScheduledTasks',
    readScheduledTaskPermission : 'readScheduledTasks',
    readInhouzSignPermission : 'readInhouzSign',
    writeInhouzSignPermission : 'writeInhouzSign',
    inhouzSignAdminPermission : 'inhouzSignAdmin',
    inhouzSignDeveloperPermission : 'inhouzSignDeveloper',
    readInhouzContactsPermission : 'readInhouzContacts',
    writeInhouzContactsPermission : 'writeInhouzContacts',
    domainApps : ['webApp', 'presentation'],
    plusCryptoString : process.env.REACT_APP_PLUS_CRYPTO_STRING,
    slashCryptoString : process.env.REACT_APP_SLASH_CRYPTO_STRING,
    equalsCryptoString : process.env.REACT_APP_EQUALS_CRYPTO_STRING,
    stripeApiKey : process.env.REACT_APP_STRIPE_API_KEY,
    csrfKey : process.env.REACT_APP_CSRF_KEY,
    clientPublicKey : process.env.REACT_APP_PUBLIC_KEY,
    appViewerDomain : process.env.REACT_APP_VIEWER_DOMAIN,
    webPushPublicKey : process.env.REACT_APP_WEB_PUSH_PUBLIC_KEY,
    NodeRsaPublicKey : new NodeRSA(process.env.REACT_APP_PUBLIC_KEY, {environment : 'browser'}),
    permittedCompanyTypes : [
        'enterpriseMax', 'enterprise', 'solopreneur', 'entrepreneur',
        'smb', 'founder'
    ],
    mediaProxyUrl : process.env.REACT_APP_MEDIA_PROXY_URL || '',
    nonFunctionalElements : ['columnCell', 'columnFilter'],
    imageFileMaxSize : 5242880,
    recurringPaymentLinkTypes : ['recurring', 'flex-schedule', 'fixed-schedule'],
    inhouzSignAppType : 'inhouzSign',
    inhouzSignFormElements : [
        'checkbox', 'fileUpload',
        'input', 'radioButton',
        'select', 'textarea', 'signature',
        'initials', 'dateSigned', 'rate',
        'textField'
    ],
    dragExemptElements : [
        'wysiwyg', 'select', 'signaturePad'
    ],
    dynamicChargeTypes : ['payment link', 'on-demand', 'inhouz sign'],
    dynamicPaymentOrigins : ['payment link', 'on-demand', 'inhouz sign'],
    inhouzSignInitialsIcon : 'https://storage.googleapis.com/inhouz_general/inhouz_sign_initials_icon.svg',
    inhouzSignHereIcon : 'https://storage.googleapis.com/inhouz_general/inhouz_sign_here_icon.svg',
    readPdfGeneratorPermission : 'readPdfGenerator',
    writePdfGeneratorPermission : 'writePdfGenerator',
    pdfGeneratorAdminPermission : 'pdfGeneratorAdmin',
    pdfGeneratorDeveloperPermission : 'pdfGeneratorDeveloper',
    inhouzCloudLogo : 'https://storage.googleapis.com/inhouz_general/inhouz_light_logo.svg',
    inhouzPayIconUrl : 'https://storage.googleapis.com/inhouz_website/inhouz_icon_graphics/Inhouz_Pay.png',
    databaseIconUrl : 'https://storage.googleapis.com/inhouz_website/inhouz_icon_graphics/Database.png',
    secretKeyIconUrl : 'https://storage.googleapis.com/inhouz_website/inhouz_icon_graphics/Secret%20Keys.png',
    contactsIconUrl : 'https://storage.googleapis.com/inhouz_website/inhouz_icon_graphics/Contacts.png',
    systemUsersIconUrl : 'https://storage.googleapis.com/inhouz_website/inhouz_icon_graphics/System_Users.png',
    // appBuilderTheme : {
    //     dark : {
    //         backgroundColor : '#404040',
    //         lightBackgroundColor : '#4d4d4d',
    //         dullColor : '#a6a6a6',
    //         sharpColor : 'white',
    //         // sharpColor : 'rgb(217, 217, 217)',
    //         canvasWrapperBackground : '#878787',
    //         settingsHeaderBackground : '#292828',
    //         borderColor : 'black',
    //         styleIconBackgroundDull : '#5e5e5e',
    //         styleIconBackgroundBright : '#6e6e6e',
    //         styleIconBorderColor : '#333333'
    //     },
    //     light : {}
    // }
}